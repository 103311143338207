import React, { useState } from "react";
import images from "../../images";

export default function Detail(props) {
  return (
    <div className="profile_detail">
      <div className="detail_form">
        <div className="form-group mb-2">
          <label>Name</label>
          <div className="form-input">
            <input
              type="text"
              className="formField w-100"
              placeholder="Enter your name"
              value={props.data.full_name}
              onChange={(e) => props.onChange("full_name", e.target.value)}
            />
          </div>
        </div>
        <div className="form-group mb-2">
          <label>Email Address</label>
          <div className="form-input">
            <input
              type="email"
              className="formField  w-100"
              placeholder="Enter your email address"
              value={props.data.email}
              onChange={(e) => props.onChange("email", e.target.value)}
            />
          </div>
        </div>
        <div className="form-group mb-2">
          <label>Phone no</label>
          <div className="form-input">
            <input
              type="text"
              className="formField  w-100"
              placeholder="Enter your phone no"
              value={props.data.phone_no}
              onChange={(e) => props.onChange("phone_no", e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
