import React, { useEffect, useState } from "react";
import Filters from "./Filter";
import "./Shop.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation, useParams } from "react-router-dom";
import { getProductsList, searchItem } from "../../API/fakebackend";
import { API_URL } from "../../API/url";
import Product from "../../common/Product";

export default function Shop() {
  const [dataState, setDataState] = useState();
  const [_filters, setFilters] = useState({});
  const [formData, setFormData] = useState({
    pocket: "",
    color: "",
    gender: "",
    // category: "",
  });

  const removeInput = () => {
    setFormData({
      pocket: "",
      color: "",
      gender: "",
      // category: "",
    });
  };

  const { id } = useParams();
  const search = useLocation();

  let URL;
  useEffect(() => {
    if (id) {
      URL = `${id}${search.search}`;
    } else if (formData.gender || formData.color || formData.pocket) {
      URL = `?gender=${formData.gender}&color=${formData.color}&pocket=${formData.pocket}`;
    } else {
      URL = `${search.search}`;
    }
  }, [formData, id, search]);

  const ShowUrl = id && id?.split("?")[0];

  const fetchData = async () => {
    try {
      const data = await getProductsList(URL);
      if (data && data.result === 1) {
        setFilters(data?.data.filters);
        const transformedData = data.data.category.map((category) => ({
          ...category,
          products: category.products.map((item) => ({
            slug: item.slug,
            name: item.product_name,
            category: item.category_name,
            price: item.regular_price,
            rating: item.overallRating?.rating,
            image: `${API_URL}/public${
              item.variation.find((variation) => !variation.is_deleted)
                .images[0]
            }`,
            color: item.variation
              .filter((variation) => !variation.is_deleted)
              .map((variation) => variation.color_hexacode),
          })),
        }));
        setDataState(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataSearch = async () => {
    let transformedData = [
      {
        products: [],
      },
    ];

    try {
      const data = await searchItem(URL);

      if (data && data.result === 1) {
        transformedData[0].products = data.data.map((item) => ({
          slug: item.slug,
          name: item.product_name,
          category: item.category_name,
          price: item.regular_price,
          rating: item.overallRating?.rating,
          image: `${API_URL}/public${
            item.variation.find((variation) => !variation.is_deleted).images[0]
          }`,
          color: item.variation
            .filter((variation) => !variation.is_deleted)
            .map((variation) => variation.color_hexacode),
        }));

        setDataState(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (search.search === "?search") {
      fetchDataSearch();
    } else {
      fetchData();
    }
  }, [URL, search, formData]);

  return (
    <main>
      <div className="shop_outer">
        <Filters
          filters={_filters}
          data={formData}
          remove={removeInput}
          setData={setFormData}
        />
        {search.search === "?search" ? (
          <div className="shop_content">
            {dataState &&
              dataState.map((category, index) => (
                <div key={index} className="shop_products_container">
                  <div className="shop_products shopPproductsSearch">
                    <h5 className="font20 sbold text-dark fontPrimary">
                      {search.search === "?search" ? ShowUrl : null}
                      {category.subcategory_name}
                      {category.category_name}
                    </h5>
                    <hr />
                    {category.products.length > 0 ? (
                      <div className="product_grid">
                        {category.products.map((product, productIndex) => (
                          <div key={productIndex} className="product_item">
                            <Product data={product} color={product.color} />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-center">
                        <b>No products found</b>
                      </p>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="shop_content">
            {dataState &&
              dataState.map((category, index) => (
                <div key={index} className="shop_products_container">
                  <div className="shop_products">
                    <div className="bestseller_slider">
                      <h5 className="font20 sbold text-dark fontPrimary">
                        {search.search === "?search" ? ShowUrl : null}
                        {category.subcategory_name}
                        {category.category_name}
                      </h5>
                      <hr />
                      <Swiper
                        spaceBetween={15}
                        slidesPerView={1.4}
                        loop={true}
                        breakpoints={{
                          1299: {
                            slidesPerView: 5.3,
                          },
                          992: {
                            slidesPerView: 3.5,
                          },
                          768: {
                            slidesPerView: 2.8,
                          },
                          575: {
                            slidesPerView: 2.3,
                          },
                        }}
                      >
                        {category.products.map((product, productIndex) => (
                          <SwiperSlide key={productIndex}>
                            <Product data={product} color={product.color} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </main>
  );
}
