import React, { useEffect, useState } from "react";
import images from "../../images";
import "./Home.css";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Autoplay } from "swiper/modules";
import Product from "../../common/Product";
import { bestSeller, navigationTree, newsLetter } from "../../API/fakebackend";
import { API_URL } from "../../API/url";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export const bestseller = [
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img1,
    rating: "4.8",
    hot: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Lab coats",
    price: "₹ 1,500.00",
    image: images.img2,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img3,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img4,
    rating: "4.8",
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Mens",
    price: "₹ 1,500.00",
    image: images.img5,
    rating: "4.8",
    hot: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img6,
    rating: "4.8",
    new: true,
  },
  {
    name: "Classic Flexi Scrub ",
    category: "Womens",
    price: "₹ 1,500.00",
    image: images.img7,
    rating: "4.8",
    new: true,
  },
];
export const color = [
  "#4C494E",
  "#243458",
  "#3A163A",
  "#D26D7B",
  "#63745E",
  "#80ACE9",
];
export default function Home() {
  const [bestSelling, setBestSelling] = useState([]);
  const [navigationTreeData, setNavigationDataTree] = useState([]);
  const [email, setEmail] = useState("");

  const [authUser, setAuthUser] = useState("");

  // useEffect(() => {
  //   setAuthUser(JSON.parse(localStorage.getItem("authUser")));
  // }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setAuthUser(JSON.parse(localStorage.getItem("authUser")));
    };

    handleStorageChange();

    window.addEventListener("storageChange", handleStorageChange);

    return () => {
      window.removeEventListener("storageChange", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (authUser) {
      fetchData();
    }
  }, [authUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = {
      email,
    };
    try {
      const response = await newsLetter(values);
      if (response.result === 1) {
        toast.success("Sign up successfully!");
        setEmail("");
      } else {
        toast.error("Failed to Sign up.");
      }
    } catch (error) {
      toast.error("An error occurred while Signing up.");
    }
  };

  const fetchData = async () => {
    try {
      const bestSellingData = await bestSeller();
      if (bestSellingData && bestSellingData.result === 1) {
        const transformedData = bestSellingData.data.map((item) => ({
          slug: item.slug,
          name: item.product_name,
          category: item.category_name,
          price: item.regular_price,
          rating: item.overallRating?.rating,
          image: `${API_URL}/public${
            item.variation.find((variation) => !variation.is_deleted).images[0]
          }`,
          color: item.variation
            .filter((variation) => !variation.is_deleted)
            .map((variation) => variation.color_hexacode),
        }));
        setBestSelling(transformedData);
      }
    } catch (error) {
      console.error("Error fetching best selling data:", error);
    }
  };

  const fetchNavigationTreeData = async () => {
    try {
      const navigationTreeData = await navigationTree();
      if (navigationTreeData && navigationTreeData.result === 1) {
        setNavigationDataTree(navigationTreeData.data);
      }
    } catch (error) {
      console.error("Error fetching navigation tree data:", error);
    }
  };

  useEffect(() => {
    if (authUser) {
      fetchNavigationTreeData();
    }
  }, [authUser]);
  const collection = [
    { name: "Men Collection", img: images.surgeon_man },
    { name: "Women Collection", img: images.surgeon_woman },
    { name: "Lab coats", img: images.lab_coats },
  ];
  const clients = [
    images.client_logo1,
    images.client_logo2,
    images.client_logo3,
    images.client_logo4,
    images.client_logo5,
    images.client_logo6,
    images.client_logo7,
  ];
  const news = [
    {
      name: "Dr. Dewang Parikh (Vardaan Nursing Home)",
      // date: "12 Feb, 2024",
      rating: 5,
      text: "As a healthcare professional, I rely on Medilinen for reliable and durable surgical supplies. Their website is easy to navigate, and the payment options are convenient. I'm very satisfied with their service. ",
      logo: images.medilinenLogo,
    },
    {
      name: "Dr. Sonal Chaudhary (Vatsalya Nursing Home)",
      // date: "12 Feb, 2024",
      rating: 4.5,
      text: "We've had nothing but positive experiences with Medilinen. Their surgical gowns and hospital supplies are always of the highest quality, and their delivery service is fast and reliable. Great job, Medilinen team!",
      logo: images.medilinenLogo,
    },
    {
      name: "Dr. Shoib (Unmat Multispeciality Hospital)",
      // date: "12 Feb, 2024",
      rating: 5,
      text: "Medilinen's delivery service is incredibly efficient. Whether it's through Delhivery or their in-house personnel, our orders always arrive on time. The quality of their hospital supplies is excellent, making them our go-to supplier. ",
      logo: images.medilinenLogo,
    },
    {
      name: "Dr. Rahul Dubey (Samarpan Hospital)",
      // date: "12 Feb, 2024",
      rating: 4.5,
      text: "The surgical gowns from Medilinen are of superior quality, providing the protection and comfort our team needs. Their customer support is also very responsive and helpful. We wouldn't shop anywhere else! ",
      logo: images.medilinenLogo,
    },
    {
      name: "Dr. Krishna vyas (Purnima Hospital)",
      // date: "12 Feb, 2024",
      rating: 5,
      text: "I appreciate Medilinen's commitment to quality and customer satisfaction. Their range of hospital supplies is extensive, and their prices are competitive. The COD payment option is a great convenience for us. ",
      logo: images.medilinenLogo,
    },
  ];
  return (
    <main>
      <section className="banner_section">
        <div className="container">
          <div className="banner_img">
            <img
              src={images.heroContent}
              alt=""
              className="img-fluid w-100 d-none d-md-block"
            />
            <div className="banner_mobile">
              <img
                src={images.heroContent_mobile}
                alt=""
                className="img-fluid w-100"
              />
            </div>
          </div>
          <div className="banner_content">
            <h1>
              Stay fresh <br />
              stay stylish
            </h1>
            <p className="mb-3 mb-xl-5">
              Fashion-forward products for
              <br />
              Healthcare heroes.
            </p>
            <NavLink to={"/collections?primary=true"} className={"common_btn"}>
              Shop Now
            </NavLink>
            <div className="banner_features">
              <h4>
                <img src={images.airware} alt="" width={17} />
                Breathable
              </h4>
              <h4>
                <img src={images.panorama_horizontal} alt="" width={17} />
                Stretchable
              </h4>
              <h4>
                <img src={images.sentiment_content} alt="" width={17} />
                Odorless
              </h4>
            </div>
          </div>
        </div>
      </section>
      <section className="collection_section common_sec">
        <div className="container">
          <div className="heading_block">
            <h4 className="mb-0">Discover your Flex</h4>
            <p>Find your taste of choice from our brand new collections</p>
          </div>
          <div className="collection_blocks">
            <div className="row gy-4">
              {navigationTreeData &&
                navigationTreeData?.menu?.map((opt, i) => {
                  return (
                    <div className="col-md-4 col-sm-6" key={i}>
                      <NavLink
                        to={`/collections${opt.link}`}
                        className="collection_block"
                      >
                        <div className="collection_img">
                          <img
                            src={`${API_URL}/public${opt.image}`}
                            alt=""
                            className="img-fluid mb-3"
                          />
                        </div>
                        <div className="collection_info">
                          <h4>
                            {opt.name}
                            <img
                              src={images.expand_circle_right}
                              alt=""
                              className="ms-1"
                            />
                          </h4>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      <section className="common_sec plus_bg">
        <div className="container">
          <div className="heading_block">
            <h4 className="mb-0">Shop our Bestselling</h4>
            <p>Get fresh and comforting products for your daily works</p>
          </div>
        </div>
        <div className="ps-sm-4 ps-2">
          <div className="bestseller_slider">
            <div className="container">
              <Swiper
                spaceBetween={15}
                slidesPerView={1.4}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                // mousewheel={true}
                loop={false}
                modules={[Mousewheel]}
                breakpoints={{
                  1299: {
                    slidesPerView: 4.8,
                  },
                  992: {
                    slidesPerView: 4.3,
                  },
                  768: {
                    slidesPerView: 3.3,
                  },
                  575: {
                    slidesPerView: 2.3,
                  },
                }}
              >
                {bestSelling &&
                  bestSelling.map((opt, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <Product data={opt} color={color} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="common_sec primary-bg scrub_section">
        <div className="container">
          <div className="banner_content scrub_content">
            <p className="mb-md-5 mb-3">
              Not all heroes wear capes <br />
              but they can
            </p>
            <h1>Flex in Scrubs</h1>
            <p className="font20">
              Get fresh and comforting products for your daily works
            </p>
          </div>
          <div className="scrub_image">
            <img src={images.scrub_new} alt="" className="img-fluid w-100" />
          </div>
        </div>
      </section>
      <section className="common_sec client_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="heading_block">
                <h4 className="">Spreading Happiness across India</h4>
                <p>Get fresh and comforting products for your daily works</p>
              </div>
              {/* <NavLink to="/" className={"common_btn btn2"}>
                Contact us for Bulk
              </NavLink> */}
            </div>
          </div>
        </div>
        <div className="client_logo_slider portfolio_slider_list_outer">
          <div className="portfolio_slider_list">
            {clients.map((opt, i) => {
              return (
                <div className="client_logo" key={i}>
                  <img src={opt} alt="" className="img-fluid" />
                </div>
              );
            })}
            {clients.map((opt, i) => {
              return (
                <div className="client_logo" key={i}>
                  <img src={opt} alt="" className="img-fluid" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="common_sec plus_peach_bg">
        <div className="container">
          <div className="heading_block">
            <h4 className="mb-0">Listen from our others</h4>
            <p>Reviews from our Customers and News Journalists</p>
          </div>
        </div>
        <div className="ps-md-4 ps-2">
          <div className="news_slider">
            <Swiper
              spaceBetween={15}
              slidesPerView={1.2}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
              // mousewheel={true}
              loop={true}
              autoplay={true}
              modules={[Mousewheel, Autoplay]}
              breakpoints={{
                1500: {
                  slidesPerView: 3.5,
                },
                767: {
                  slidesPerView: 2.5,
                },
                575: {
                  slidesPerView: 2.2,
                },
              }}
            >
              {news.map((opt, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="card custom_card p-4">
                      <div className="news_header">
                        <span className="news_header_img">
                          <img src={opt.logo} alt="" />
                        </span>
                        <div>
                          <p className="font16 med text-dark mb-0">
                            {opt.name}
                          </p>
                          <p className="font14 text-dark">{opt.date}</p>
                        </div>
                      </div>
                      <div className="news_content">
                        <p className="font14 text-dark">{opt.text}</p>
                      </div>
                      <div className="d-flex justify-content-between font14 align-items-center">
                        {opt.rating} rating
                        <img src={images.stars} alt="" width={90} />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="common_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="heading_block">
                <h4 className="">Wanna know more</h4>
                <p>
                  Sign up for our Newsletters and Latest update in our inventory
                </p>
              </div>
            </div>
            <div className="col-md-5 text-end">
              <input
                type="text"
                className="formField w-100 mb-2"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className={"common_btn btn2"} onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );
}
