import React, { useEffect, useState } from "react";
import images from "../images";
import { signUp } from "../API/fakebackend";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Signup(props) {
  const [cookies] = useCookies(["device_id"]);
  const [step, setStep] = useState("1");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [companyDetails, SetCompanyDetails] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const url = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_no: "",
    password: "",
    confirmPassword: "",
    address: [
      {
        address_line_1: "",
        address_line_2: "",
        address_line_3: "",
        pin_code: "",
        state: "",
        country: "",
        companyDetail: companyDetails,
        gstNo: "",
        companyName: "",
      },
    ],
    device_id: cookies.device_id || "",
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: prevFormData.address.map((address) => ({
        ...address,
        companyDetail: companyDetails,
      })),
    }));
  }, [companyDetails]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    delete newErrors[name];

    if (name.includes("address")) {
      const addressIndex = 0;
      const addressName = name.split(".")[1];
      const newAddress = [...formData.address];
      newAddress[addressIndex][addressName] = value;
      setFormData({ ...formData, address: newAddress });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setErrors(newErrors);
  };

  const handleCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const handleCheckboxChangeCompany = (e) => {
    SetCompanyDetails(e.target.checked);
  };

  const validateStep1 = () => {
    const newErrors = {};

    const trimmedFullName = formData.full_name?.trim();
    if (!trimmedFullName) {
      newErrors.full_name = "Full name is required";
    } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(trimmedFullName)) {
      newErrors.full_name = "Full name should only contain letters and spaces";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.phone_no) {
      newErrors.phone_no = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone_no)) {
      newErrors.phone_no = "Phone number should be 10 digits";
    }

    if (!formData.password) newErrors.password = "Password is required";

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    return newErrors;
  };

  const validateStep2 = () => {
    const newErrors = {};

    const addressLine1 = formData.address[0].address_line_1?.trim();
    const addressLine2 = formData.address[0].address_line_2?.trim();
    const pinCode = formData.address[0].pin_code?.trim();
    const state = formData.address[0].state?.trim();
    const country = formData.address[0].country?.trim();
    const companyDetail = formData.address[0].companyDetail;
    const gstNo = formData.address[0].gstNo?.trim();
    const companyName = formData.address[0].companyName?.trim();

    if (!addressLine1) {
      newErrors["address.address_line_1"] = "Address line 1 is required";
    }

    if (!addressLine2) {
      newErrors["address.address_line_2"] = "Address line 2 is required";
    }

    if (!pinCode) {
      newErrors["address.pin_code"] = "Pincode is required";
    } else if (!/^\d{6}$/.test(pinCode)) {
      newErrors["address.pin_code"] = "Pincode should be 6 digits";
    }

    if (!state) {
      newErrors["address.state"] = "State is required";
    } else if (/\d/.test(state)) {
      newErrors["address.state"] = "State should not contain numbers";
    }

    if (!country) {
      newErrors["address.country"] = "Country is required";
    } else if (/\d/.test(country)) {
      newErrors["address.country"] = "Country should not contain numbers";
    }

    if (companyDetail) {
      if (!companyName) {
        newErrors["address.companyName"] = "Company Name is required";
      }
      if (!gstNo) {
        newErrors["address.gstNo"] = "GST Number is required";
      }
    }
    return newErrors;
  };

  const formatFormData = () => {
    return {
      email: formData.email,
      password: formData.password,
      confirm_password: formData.confirmPassword,
      full_name: formData.full_name,
      username: formData.email.split("@")[0],
      phone_no: formData.phone_no,
      address: [
        {
          address_line_1: formData.address[0].address_line_1,
          address_line_2: formData.address[0].address_line_2,
          address_line_3: formData.address[0].address_line_3,
          pin_code: formData.address[0].pin_code,
          state: formData.address[0].state,
          country: formData.address[0].country,
          companyDetail: formData.address[0].companyDetail,
          companyName: formData.address[0].companyName,
          gstNo: formData.address[0].gstNo,
        },
      ],
      device_id: formData.device_id,
    };
  };

  const handleSubmit = async () => {
    try {
      const formattedData = formatFormData();
      const response = await signUp(formattedData);

      if (response.result === 1) {
        localStorage.setItem("authUser", JSON.stringify(response));
        navigate(url.pathname);
      } else {
        toast.error(response.data.msg, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response?.data?.msg || "Something went wrong", {
        autoClose: 2000,
      });
    }
  };

  const handleNextStep = () => {
    const newErrors = validateStep1();
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setStep("2");
    }
  };

  const goBack = () => {
    setStep("1");
  };

  const handleFinalSubmit = () => {
    const newErrors = validateStep2();
    if (!termsAccepted) {
      newErrors.terms = "You must accept the terms and conditions";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      handleSubmit();
    }
  };

  return (
    <div className={`login_modal ${props.show ? "show" : ""}`}>
      <div className="signup_modal_container">
        <div className="row justify-content-center g-5">
          <div className="col-md-6 d-none d-xl-block">
            <img
              src={images.signup_img_new}
              alt=""
              className="img-fluid w-100"
            />
          </div>
          <div className="col-md-6">
            {step === "1" ? (
              <div className="signup_modal_col">
                <div className="login_modal_header">
                  <img src={images.logo} alt="" width={200} />
                  <button onClick={props.handleClose}>
                    <img src={images.close} alt="" width={14} />
                  </button>
                </div>
                <div className="login_modal_form">
                  <div className="login_modal_header mb-5">
                    <div>
                      <h4 className="font20 text-dark bold fontPrimary2 mb-2">
                        Sign up
                      </h4>
                      <p>Enter your details</p>
                    </div>
                    <div>
                      <p>Step 1/2</p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-2">
                      <input
                        type="text"
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors.full_name ? "is-invalid" : ""
                        }`}
                        placeholder="Your Full name"
                      />
                      {errors.full_name && (
                        <div className="invalid-feedback">
                          {errors.full_name}
                        </div>
                      )}
                    </div>
                    <div className="mb-2">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Enter your email address"
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                    <div className="mb-2">
                      <input
                        type="text"
                        name="phone_no"
                        value={formData.phone_no}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors.phone_no ? "is-invalid" : ""
                        }`}
                        placeholder="Phone Number"
                      />
                      {errors.phone_no && (
                        <div className="invalid-feedback">
                          {errors.phone_no}
                        </div>
                      )}
                    </div>
                    <div className="mb-2">
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="New Password"
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="mb-2 d-flex">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors.confirmPassword ? "is-invalid" : ""
                        }`}
                        placeholder="Confirm Password"
                      />
                      <button
                        type="button"
                        className="right_btn ms-2"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <img
                          src={
                            !showPassword
                              ? images.visibility
                              : images.visibility_off
                          }
                          alt=""
                        />
                      </button>
                      {errors.confirmPassword && (
                        <div className="invalid-feedback">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>
                    <div className="login_modal_header mt-5 align-items-center">
                      <button
                        className={"primary-text font14 med"}
                        onClick={() => props.handleClick("login")}
                      >
                        Login your Account
                      </button>
                      <button
                        className="common_btn primary btn2"
                        onClick={handleNextStep}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="signup_modal_col">
                <div className="login_modal_header">
                  <img src={images.logo} alt="" width={200} />
                  <button onClick={props.handleClose}>
                    <img src={images.close} alt="" width={14} />
                  </button>
                </div>
                <div className="login_modal_form">
                  <div className="login_modal_header mb-5">
                    <div>
                      <h4 className="font20 text-dark bold fontPrimary2 mb-2">
                        Sign up
                      </h4>
                      <p>Enter your details</p>
                    </div>
                    <div>
                      <p>Step 2/2</p>
                    </div>
                  </div>
                  <div>
                    <div className="mb-2">
                      <input
                        type="text"
                        name="address.address_line_1"
                        value={formData.address[0].address_line_1}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors["address.address_line_1"] ? "is-invalid" : ""
                        }`}
                        placeholder="Address line 1"
                      />
                      {errors["address.address_line_1"] && (
                        <div className="invalid-feedback">
                          {errors["address.address_line_1"]}
                        </div>
                      )}
                    </div>
                    <div className="mb-2">
                      <input
                        type="text"
                        name="address.address_line_2"
                        value={formData.address[0].address_line_2}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors["address.address_line_2"] ? "is-invalid" : ""
                        }`}
                        placeholder="Address line 2"
                      />
                      {errors["address.address_line_2"] && (
                        <div className="invalid-feedback">
                          {errors["address.address_line_2"]}
                        </div>
                      )}
                    </div>
                    <div className="mb-2">
                      <input
                        type="text"
                        name="address.address_line_3"
                        value={formData.address[0].address_line_3}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors["address.address_line_3"] ? "is-invalid" : ""
                        }`}
                        placeholder="Address line 3"
                      />
                    </div>
                    <div className="mb-2">
                      <input
                        type="text"
                        name="address.pin_code"
                        value={formData.address[0].pin_code}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors["address.pin_code"] ? "is-invalid" : ""
                        }`}
                        placeholder="Pin Code"
                      />
                      {errors["address.pin_code"] && (
                        <div className="invalid-feedback">
                          {errors["address.pin_code"]}
                        </div>
                      )}
                    </div>
                    <div className="mb-2">
                      <input
                        type="text"
                        name="address.state"
                        value={formData.address[0].state}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors["address.state"] ? "is-invalid" : ""
                        }`}
                        placeholder="State"
                      />
                      {errors["address.state"] && (
                        <div className="invalid-feedback">
                          {errors["address.state"]}
                        </div>
                      )}
                    </div>
                    <div className="mb-2">
                      <input
                        type="text"
                        name="address.country"
                        value={formData.address[0].country}
                        onChange={handleChange}
                        className={`formField w-100 ${
                          errors["address.country"] ? "is-invalid" : ""
                        }`}
                        placeholder="Country"
                      />
                      {errors["address.country"] && (
                        <div className="invalid-feedback">
                          {errors["address.country"]}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="termsCheck"
                        checked={companyDetails}
                        onChange={handleCheckboxChangeCompany}
                      />
                      <label className="form-check-label" htmlFor="termsCheck">
                        Add company details (optional)
                      </label>
                    </div>
                    {companyDetails === true && (
                      <>
                        {" "}
                        <div className="mb-2">
                          <input
                            type="text"
                            name="address.companyName"
                            value={formData.address[0].companyName}
                            onChange={handleChange}
                            className={`formField w-100 ${
                              errors["address.companyName"] ? "is-invalid" : ""
                            }`}
                            placeholder="Company Name"
                          />
                          {errors["address.companyName"] && (
                            <div className="invalid-feedback">
                              {errors["address.companyName"]}
                            </div>
                          )}
                        </div>
                        <div className="mb-2">
                          <input
                            type="text"
                            name="address.gstNo"
                            value={formData.address[0].gstNo}
                            onChange={handleChange}
                            className={`formField w-100 ${
                              errors["address.gstNo"] ? "is-invalid" : ""
                            }`}
                            placeholder="GST Number"
                          />
                          {errors["address.gstNo"] && (
                            <div className="invalid-feedback">
                              {errors["address.gstNo"]}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    <div className="mb-3 form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="termsCheck"
                        checked={termsAccepted}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="termsCheck">
                        I have read and agree to the{" "}
                        <Link
                          to={"/terms-and-condition"}
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <u>terms and conditions</u>
                        </Link>
                      </label>
                      {errors.terms && (
                        <div className="invalid-feedback d-block">
                          {errors.terms}
                        </div>
                      )}
                    </div>
                    <div className="login_modal_header mt-5 align-items-center">
                      <button
                        className="common_btn primary2 btn2"
                        onClick={goBack}
                      >
                        Back
                      </button>
                      <button
                        className="common_btn primary btn2"
                        onClick={handleFinalSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
