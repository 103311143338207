import React, { useEffect, useState } from "react";
import images from "../../images";
import { Accordion } from "react-bootstrap";

export default function Filters(props) {
  const [filterData, setFilterData] = useState(props.filters);
  const [selectedFilters, setSelectedFilters] = useState({
    gender: [],
    pockets: [],
    colors: [],
  });

  const resetData = () => {
    setSelectedFilters({
      gender: [],
      pockets: [],
      colors: [],
    });
    props.remove();
  };

  useEffect(() => {
    setFilterData(props.filters);
  }, [props.filters]);

  const addFilter = () => {
    props.setData({
      pocket: selectedFilters.pockets.join(","),
      color: selectedFilters.colors.join(","),
      gender: selectedFilters.gender.join(","),
    });
  };

  const filters = [
    {
      name: "Gender",
      icon: images.wc,
      list: ["Men", "Women", "more"],
    },
    {
      name: "Pockets",
      icon: images.sim_card_download,
      list: filterData?.pockets?.map((e) => e._id),
    },
    {
      name: "Colors",
      icon: images.color,
      list: filterData?.color?.map((color) => ({
        _id: color._id,
        name: color.color_name,
        color: color.color_hexacode,
      })),
    },
  ];

  const handleFilterChange = (filterType, value) => {
    setSelectedFilters((prevFilters) => {
      const currentFilterValues = prevFilters[filterType];
      const isValueSelected = currentFilterValues.includes(value);

      const updatedFilterValues = isValueSelected
        ? currentFilterValues.filter((v) => v !== value)
        : [...currentFilterValues, value];

      return {
        ...prevFilters,
        [filterType]: updatedFilterValues,
      };
    });
  };

  const [showFilter, setShowFilter] = useState(false);

  return (
    <div className="shop_sidebar">
      <div className="shop_filters">
        <button
          onClick={() => setShowFilter(!showFilter)}
          className="w-100 shop_filters_header"
        >
          <h6 className="fontPrimary med mb-0">Filters</h6>
        </button>
        <div className={`shop_filters_accordion ${showFilter ? "show" : ""}`}>
          <Accordion>
            {filters.map((opt, i) => (
              <Accordion.Item eventKey={i} key={i}>
                <Accordion.Header>
                  <span className="me-3">
                    <img src={opt.icon} alt="" />
                  </span>
                  {opt.name}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="filter_options">
                    {opt.name === "Colors"
                      ? opt.list?.map((val) => (
                          <label key={val._id} className="filter_opt_btns">
                            <input
                              type="checkbox"
                              checked={selectedFilters.colors.includes(val._id)}
                              onChange={() =>
                                handleFilterChange("colors", val._id)
                              }
                            />
                            <span>
                              <span
                                className="color_dot me-2"
                                style={{ backgroundColor: val.color }}
                              ></span>
                              {val.name}
                            </span>
                          </label>
                        ))
                      : opt.list?.map((val, index) => (
                          <label key={index} className="filter_opt_btns">
                            <input
                              type="checkbox"
                              checked={
                                opt.name === "Gender"
                                  ? selectedFilters.gender.includes(val)
                                  : selectedFilters.pockets.includes(val)
                              }
                              onChange={() =>
                                handleFilterChange(
                                  opt.name === "Gender" ? "gender" : "pockets",
                                  val
                                )
                              }
                            />
                            <span>
                              {typeof val === "string" ? val : String(val)}
                            </span>
                          </label>
                        ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <div className="d-flex justify-content-between mt-4">
            <button className="font14 med primary-text" onClick={addFilter}>
              Apply
            </button>
            <button className="font14 reg" onClick={resetData}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
