import React, { useEffect, useState } from "react";
import "./Profile.css";
import Detail from "./Detail";
import LoginDetails from "./LoginDetails";
import Address from "./Address";
import {
  deleteAddress,
  deleteUser,
  fetchUserProfile,
  updateUserProfile,
} from "../../API/fakebackend";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../../../App";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Profile() {
  const [authUser, setAuthUser] = useState("");
  const [dataState, setDataState] = useState("");
  const [change, setChange] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_no: "",
    address: [],
  });

  useEffect(() => {
    setFormData({
      ...formData,
      full_name: dataState.full_name,
      email: dataState.email,
      phone_no: dataState.phone_no,
      address: dataState.address,
    });
  }, [dataState]);

  const handleInputChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleAddressChange = (index, updatedAddress) => {
    setFormData((prevState) => ({
      ...prevState,
      address: prevState.address.map((addr, addrIndex) =>
        addrIndex === index ? updatedAddress : addr
      ),
    }));
  };

  useEffect(() => {
    setAuthUser(JSON.parse(localStorage.getItem("authUser")));
  }, []);

  const fetchData = async () => {
    try {
      if (authUser?.data?.user?._id) {
        const response = await fetchUserProfile(authUser?.data?.user?._id);
        setDataState(response.data);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "An error occurred while fetching data",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const validateForm = () => {
    if (!formData.full_name.trim()) {
      toast.error("Full name cannot be empty", { autoClose: 2000 });
      return false;
    }

    const nameRegex = /^[a-zA-Z\s-]+$/;

    if (!nameRegex.test(formData.full_name)) {
      toast.error("Full name must contain only letters, spaces, or hyphens", {
        autoClose: 2000,
      });
      return false;
    }

    const phoneRegex = /^[0-9]+$/;
    if (!phoneRegex.test(formData.phone_no)) {
      toast?.error("Phone number must contain only numbers", {
        autoClose: 2000,
      });
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast?.error("Invalid email format", { autoClose: 2000 });
      return false;
    }

    for (let i = 0; i < formData?.address?.length; i++) {
      const address = formData?.address[i];

      if (!address?.address_line_1?.trim()) {
        toast?.error(`Address line 1 cannot be empty (Address ${i + 1})`, {
          autoClose: 2000,
        });
        return false;
      }
      if (!address?.address_line_2?.trim()) {
        toast?.error(`Address line 2 cannot be empty (Address ${i + 1})`, {
          autoClose: 2000,
        });
        return false;
      }

      if (!address?.state?.trim()) {
        toast?.error(`State cannot be empty (Address ${i + 1})`, {
          autoClose: 2000,
        });
        return false;
      }
      if (!address?.country?.trim()) {
        toast?.error(`Country cannot be empty (Address ${i + 1})`, {
          autoClose: 2000,
        });
        return false;
      }

      if (!address?.pin_code?.trim()) {
        toast?.error(`Pincode cannot be empty (Address ${i + 1})`, {
          autoClose: 2000,
        });
        return false;
      }

      if (address?.companyDetail) {
        if (!address?.gstNo?.trim()) {
          toast?.error(`GST Number cannot be empty (Address ${i + 1})`, {
            autoClose: 2000,
          });
          return false;
        }

        if (!address?.companyName?.trim()) {
          toast?.error(`Company Name cannot be empty (Address ${i + 1})`, {
            autoClose: 2000,
          });
          return false;
        }
      }
    }

    return true;
  };

  const updateProfile = async (values) => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await updateUserProfile(
        authUser?.data?.user?._id,
        values
      );
      if (response.data.result === 1) {
        toast.success(response.data.message, { autoClose: 2000 });
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred", {
        autoClose: 2000,
      });
    }
  };

  const deleteUserAddress = async (value) => {
    try {
      const data = {
        addressId: value,
      };
      const response = await deleteAddress(authUser?.data?.user?._id, data);
      if (response.result === 1) {
        toast.success(response.message, { autoClose: 2000 });
        fetchData();
      } else {
        toast.error(response.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred", {
        autoClose: 2000,
      });
    }
  };

  const deleteProfile = async (value) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = {
            addressId: value,
          };
          const response = await deleteUser(authUser?.data?.user?._id, data);
          if (response.result === 1) {
            toast.success(response.message, { autoClose: 2000 });
            logout();
          } else {
            toast.error(response.message, { autoClose: 2000 });
          }
        } catch (error) {
          toast.error(error.response?.data?.message || "An error occurred", {
            autoClose: 2000,
          });
        }
      }
    });
  };

  const asideItems = [
    {
      id: 1,
      name: "My Details",
      link: "my-details",
      render: () => (
        <Detail
          data={formData}
          onChange={(fieldName, value) => handleInputChange(fieldName, value)}
        />
      ),
    },
    {
      id: 2,
      name: "Address",
      link: "address",
      render: () => (
        <Address
          data={formData.address}
          onChange={handleAddressChange}
          onDelete={deleteUserAddress}
          onSubmission={fetchData}
          change={setChange}
          changeData={change}
        />
      ),
    },
    // {
    //   id: 3,
    //   name: "Login Details",
    //   link: "login-details",
    //   render: () => <LoginDetails />,
    // },
  ];

  const [active, setActive] = useState("my-details");

  const handleClick = (opt) => {
    setActive(opt.link);
  };

  useEffect(() => {
    fetchData();
  }, [authUser, active, change]);

  return (
    <main>
      <ToastContainer />
      <div className="profile_container">
        <div className="profile_header">
          <h5 className="fontPrimary2 font16 bold primary-text mb-0">
            My Profile
          </h5>
        </div>
        <div className="profile_body">
          <div className="profile_sidebar">
            {asideItems.map((opt) => (
              <button
                className={active === opt.link ? "active" : ""}
                key={opt.id}
                onClick={() => handleClick(opt)}
              >
                {opt.name}
              </button>
            ))}
          </div>
          <div className="profile_content">
            {asideItems
              .filter((x) => x.link === active)
              .map((opt) => (
                <div key={opt.id}>{opt.render()}</div>
              ))}
          </div>
        </div>
        <div className="profile_footer">
          <button
            className="common_btn primary2 btn2 me-2"
            style={{ minWidth: 100 }}
            onClick={() => {
              deleteProfile();
            }}
          >
            Delete My Account
          </button>
          <button
            className="common_btn btn2 primary"
            style={{ minWidth: 100 }}
            onClick={() => {
              updateProfile(formData);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </main>
  );
}
