import React, { useEffect, useState } from "react";
import images from "../../images";
import "./MyOrder.css";
import { Modal } from "react-bootstrap";
import {
  addReview,
  cancelOrderAPI,
  fetchOrderDetail,
  uploadReviewImage,
} from "../../API/fakebackend";
import { API_URL } from "../../API/url";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatNumberWithCommas } from "../../../App";
import RviewBox from "../../common/RviewBox/RviewBox";
import Rating from "react-rating";
const cancelOrder = [
  { name: "Mistakenly ordered item", id: "Mistakenly ordered item" },
  { name: "Got another offer", id: "Got another offer" },
  { name: "Want to order other item", id: "Want to order other item" },
  { name: "Dont need anymore", id: "Dont need anymore" },
];

const EmptyCart = () => {
  return (
    <div className="empty_cart">
      <div className="mb-2">
        <img src={images.shopping_bag} alt="" width={34} />
      </div>
      <p className="primary-text font18 fontPrimary2">
        You have not placed any orders.
      </p>
    </div>
  );
};

export default function MyOrder() {
  const [orderData, setOrderData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modelType, setModelType] = useState("");
  const [orderId, setOrderId] = useState("");
  const [trackingDetail, setTrackingDetail] = useState("");
  const [logs, setLogs] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [reviewData, setReviewData] = useState({
    userId: "",
    productId: "",
    variationId: "",
    orderId: "",
    images: [],
    review: "",
    rating: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchOrderDetail("");
      setOrderData(data.data || []);
    } catch (error) {
      console.error("Error fetching order detail data:", error);
    }
  };

  const handleRadioChange = (event) => {
    setCancelReason(event.target.value);
  };

  const handleCancelOrder = async () => {
    try {
      const response = await cancelOrderAPI({ orderId, cancelReason });
      if (response.result === 1) {
        fetchData();
      }
      setCancelReason("");
      setShowModal(false);
    } catch (error) {
      toast.error("Something went wrong while cancelling the order");
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const openModal = (type, value, id, userId) => {
    if (type === "trackOrder") {
      setTrackingDetail(value?.trackDetail);
      setLogs(value?.logs);
      setOrderStatus(value?.status);
    } else if (type === "Rating") {
      setReviewData({
        ...reviewData,
        userId: userId || "",
        productId: value.product_id || "",
        variationId: value.variationId || "",
        orderId: id || "",
      });
    }
    setOrderId(value);
    setModelType(type);
    setShowModal(true);
  };

  const handleChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setReviewData((prevState) => ({
      ...prevState,
      images: prevState.images ? prevState.images.concat(newFiles) : newFiles,
    }));
  };

  const handleDelete = (index) => {
    const newImages = reviewData.images.filter((_, i) => i !== index);
    setReviewData({
      ...reviewData,
      images: newImages,
    });
  };

  const AddReview = async () => {
    try {
      const data = await uploadReviewImage(reviewData.images);
      const values = {
        userId: reviewData?.userId || "",
        productId: reviewData?.productId || "",
        variationId: reviewData?.variationId || "",
        orderId: reviewData?.orderId || "",
        images: data?.data || [],
        review: reviewData?.review || "",
        rating: reviewData?.rating || "",
      };

      const response = await addReview(values);

      if (response.result === 1) {
        toast.success("Review added successfully!");
        handleClose();
      } else {
        toast.error("Failed to add review. Please try again.");
      }
    } catch (error) {
      console.error("Error adding review:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="cartDtlSctin fontPrimary2 pb-md-5">
        <div className="container">
          <div className="cartDtlSctin__row row justify-content-center">
            <div className="col-md-8">
              <div className="cartDtlSctin__headingholder">
                <h6 className="cartDtlSctin__heading font16 primary-text bold">
                  Your Orders
                </h6>
              </div>
              {orderData.length === 0 ? (
                <EmptyCart />
              ) : (
                <>
                  {orderData.map((order, orderIndex) => (
                    <div
                      key={orderIndex}
                      className="orderStatusBox cartOrderBox"
                    >
                      <div className="orderStatusBox__top d-flex flex-column">
                        <div className="cartOrderBox__header d-flex justify-content-between mb-3">
                          {/* Order information like date, order number, status, and amount */}
                          <div className="d-flex cartOrderBox__top">
                            <div>
                              <div className="font12 med text-light2">
                                Order Date
                              </div>
                              <div className="font14 med text-dark">
                                {order.createdAt}
                              </div>
                            </div>
                            <div>
                              <div className="font12 med text-light2">
                                Order no
                              </div>
                              <div className="font14 med text-dark">
                                {order.order_number}
                              </div>
                            </div>
                            <div>
                              <div className="font12 med text-light2">
                                Status
                              </div>
                              <div className="font14 med text-dark">
                                {order.status}
                              </div>
                            </div>
                            <div>
                              <div className="font12 med text-light2">
                                Payment Mode
                              </div>
                              <div className="font14 med text-dark">
                                {order.payment_mode}
                              </div>
                            </div>
                          </div>
                          <div className="text-end">
                            <div className="font12 med text-light2">Amount</div>
                            <div className="font14 med text-dark">
                              ₹{formatNumberWithCommas(order.total)}
                            </div>
                          </div>
                        </div>

                        {/* Mapping through items of each order */}
                        {order.items.map((item, itemIndex) => (
                          <>
                            <div
                              key={itemIndex}
                              className="cartTable__list d-flex flex-wrap"
                            >
                              <div className="cartTable__count">
                                {itemIndex + 1}
                              </div>
                              <div className="cartTable__preduct d-flex">
                                <div className="cartTable__preductImg">
                                  <div className="cart_item_img">
                                    <img
                                      src={`${API_URL}/public${item.variation?.images?.[0]}`}
                                      alt=""
                                      className="img-fluid w-100"
                                    />
                                  </div>
                                </div>
                                <div className="cartTable__preductCntnt">
                                  <div className="cart_item_info">
                                    <h5 className="font16 bold mb-1 fontPrimary2 text-dark">
                                      {item.product_name} ({item.quantity}x)
                                    </h5>
                                    <p className="font14">{item.gender}</p>
                                    <div className="font14 med mt-2">
                                      <span
                                        className="color_dot me-2"
                                        style={{
                                          backgroundColor: item.color_hexacode,
                                        }}
                                      ></span>
                                      {`${
                                        item.size.length
                                          ? item.size[0].size_title
                                          : ""
                                      }${
                                        item.size_top.length ||
                                        item.size_bottom.length
                                          ? `Top: ${
                                              item.size_top.length
                                                ? item.size_top[0].size_title
                                                : ""
                                            }, Bottom: ${
                                              item.size_bottom.length
                                                ? item.size_bottom[0].size_title
                                                : ""
                                            }`
                                          : ""
                                      }`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cartTable__price">
                                <div>₹{formatNumberWithCommas(item.total)}</div>
                                {order.status === "DELIVERED" && (
                                  <div>
                                    <button
                                      className="orderStatusBox__button mt-2 mb-2"
                                      onClick={() =>
                                        openModal(
                                          "Rating",
                                          item,
                                          order._id,
                                          order.user_id
                                        )
                                      }
                                    >
                                      <img src={images.star} /> Add Review
                                    </button>
                                  </div>
                                )}
                              </div>

                              {/* Customization details if present */}
                              {item.customization === true &&
                                (item.customizations?.fontStyle ||
                                  item.customizations?.fontColor ||
                                  item.customizations?.text ||
                                  item.customizations?.logoUrl) && (
                                  <>
                                    <div className="mb-1">Customization:</div>
                                    <div className="selectedLogoDetails__left d-flex align-items-center">
                                      <div className="d-flex align-items-center flex-grow-1">
                                        {item.customizations.logoUrl && (
                                          <div className="img">
                                            <img
                                              src={`${API_URL}/public${
                                                item.customizations.logoUrl.startsWith(
                                                  "["
                                                )
                                                  ? item.customizations.logoUrl.replace(
                                                      /[\[\]]/g,
                                                      ""
                                                    ) // Remove square brackets
                                                  : item.customizations.logoUrl
                                              }`}
                                              alt="Logo Preview"
                                            />
                                          </div>
                                        )}
                                        <div
                                          className={`content ${
                                            item.customizations.logoUrl
                                              ? "ml-2 ms-2"
                                              : ""
                                          }`}
                                        >
                                          <div className="name font16 med text-dark">
                                            {item.customizations?.text}
                                          </div>
                                          <div className="desc font12 primary-text">
                                            {item.customizations?.fontColor},{" "}
                                            {item.customizations?.fontStyle}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-end ml-auto">
                                        <h5 className="font16 bold fontPrimary2 text-dark mb-0">
                                          ₹
                                          {formatNumberWithCommas(
                                            item.customizationPrice
                                          )}
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div>
                          </>
                        ))}

                        {/* Buttons for actions like download invoice, track order, cancel order */}
                        {order.status !== "CANCELLED" &&
                        order.status !== "ORDER PLACED" ? (
                          <div className="orderStatusBox__bottom d-flex">
                            <a
                              className="orderStatusBox__button"
                              href={`${API_URL}${order?.invoice}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={images.download}
                                alt="Download invoice slip"
                              />{" "}
                              Download invoice Slip
                            </a>
                            <button
                              className="orderStatusBox__button"
                              onClick={() => openModal("trackOrder", order)}
                            >
                              <img src={images.distance} /> Track order
                            </button>
                          </div>
                        ) : null}
                        {order.status === "ORDER PLACED" && (
                          <div className="orderStatusBox__bottom d-flex">
                            {/* <a
                          className="orderStatusBox__button"
                          href={`${API_URL}${order?.invoice}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={images.download}
                            alt="Download invoice slip"
                          />{" "}
                          Download invoice Slip
                        </a> */}
                            <button
                              className="orderStatusBox__button cancel"
                              onClick={() =>
                                openModal("cancelOrder", order._id)
                              }
                            >
                              <img src={images.redCross} /> Cancel order
                            </button>
                          </div>
                        )}
                        {order.status === "CANCELLED" && (
                          <div className="orderStatusBox__bottom d-flex">
                            <div className="orderStatusBox__message text-danger">
                              You have Cancelled This Order (
                              {order.cancelReason})
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Modal for different actions */}
      <Modal show={showModal} onHide={handleClose} centered size="md">
        <Modal.Header closeButton className="">
          <Modal.Title>
            <h5 className="mb-0 font20 fontPrimary2">
              {modelType === "cancelOrder" && <>Cancel order</>}
              {modelType === "trackOrder" && <>Track order</>}
              {modelType === "Rating" && <>Rate Your Order</>}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modelType === "cancelOrder" && (
            <>
              <div className="font14 text-light2 mb-1">
                Reason for cancellation
              </div>
              <div className="radioType2 d-flex flex-column">
                {cancelOrder.map((data, i) => (
                  <div key={i} className="radioType2__box">
                    <input
                      type="radio"
                      id={data.id}
                      name="cancelReason"
                      value={data.name}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor={data.id}>{data.name}</label>
                  </div>
                ))}
              </div>
              <div className="text-center mt-3">
                <button
                  className="common_btn primary btn2"
                  onClick={handleCancelOrder}
                >
                  Cancel Order
                </button>
              </div>
            </>
          )}
          {modelType === "Rating" && (
            <>
              <div className="font14 text-light2 mb-1">
                Review (Rate Your Order)
              </div>
              <div className="mb-3 mt-3">
                <Rating
                  emptySymbol="fa fa-star-o fa-2x"
                  fullSymbol="fa fa-star fa-2x"
                  initialRating={reviewData.rating}
                  fractions={2}
                  onChange={(value) => {
                    setReviewData({
                      ...reviewData,
                      rating: value,
                    });
                  }}
                />
              </div>

              <div className="customize__spacing">
                <input
                  type="text"
                  className="formField w-100"
                  placeholder="Review"
                  name="text"
                  value={reviewData.review}
                  onChange={(e) => {
                    setReviewData({
                      ...reviewData,
                      review: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="imageUploadCstm customize__spacing">
                <input
                  type="file"
                  id="logoUpload"
                  multiple
                  onChange={handleChange}
                />
                <label htmlFor="logoUpload">
                  <div className="imageUploadCstm__img">
                    <img src={images.addphoto} alt="" />
                  </div>
                  <div className="med font14 primary-text">
                    Upload your Logo here
                  </div>
                  <div className="font12 fontPrimary2">
                    JPEG or PNG, Minimum resolution 200px by 200px, Maximum size
                    5MB
                  </div>
                </label>
              </div>
              {reviewData.images && (
                <div className="selectedLogoDetails customize__spacing ">
                  {reviewData.images.map((file, index) => (
                    <div
                      key={index}
                      className="selectedLogoDetails__left d-flex justify-content-between mb-3"
                    >
                      <div className="img">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Preview ${index}`}
                        />
                      </div>
                      <div className="content">
                        <div className="name font14 med text-dark">
                          {file.name}
                        </div>
                      </div>
                      <ul className="selectedLogoDetails__right d-flex">
                        <li>
                          <button onClick={() => handleDelete(index)}>
                            <img src={images.delete} alt="Delete Logo" />
                          </button>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              )}
              <div className="text-center mt-3">
                <button className="common_btn primary btn2" onClick={AddReview}>
                  Add Review
                </button>
              </div>
            </>
          )}

          {modelType === "trackOrder" && (
            <>
              <div className="font14 text-light2 mt-4 mb-3">
                Status Logs<br></br>{" "}
                <p>
                  Your order will be delivered within 5 to 7 days from the date
                  of order placement.
                </p>
              </div>
              <div
                className="radioType2 d-flex flex-column p-3"
                style={{ backgroundColor: "#f8f9fa", borderRadius: "8px" }}
              >
                {logs?.length > 0 ? (
                  logs.map((log, index) => (
                    <div key={index} className="log-entry mb-4">
                      <div className="d-flex align-items-center">
                        <div className="pointer me-2">•</div>
                        <div className="status-badge to-status">{log.to}</div>
                      </div>
                      <div className="log-time text-secondary mt-2">
                        <strong>Time:</strong>{" "}
                        {new Date(log.createdAt).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        })}
                        ,{" "}
                        {new Date(log.createdAt).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: true,
                        })}
                      </div>
                      {log.remark && (
                        <div className="log-remark mt-1">
                          <strong>Remark:</strong> {log.remark}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div>No status logs available.</div>
                )}
              </div>
              {orderStatus !== "DELIVERED" && (
                <>
                  {(trackingDetail?.deliveryPartner ||
                    trackingDetail?.trackingId ||
                    trackingDetail?.trackingSite) && (
                    <>
                      <div className="font14 text-light2 mb-3">
                        Tracking Detail
                      </div>
                      <div
                        className="radioType2 d-flex flex-column p-3"
                        style={{
                          backgroundColor: "#f8f9fa",
                          borderRadius: "8px",
                        }}
                      >
                        {trackingDetail?.deliveryPartner && (
                          <div className="mb-2">
                            <strong>Tracking Partner:</strong>{" "}
                            {trackingDetail.deliveryPartner}
                          </div>
                        )}
                        {trackingDetail?.trackingId && (
                          <div className="mb-2">
                            <strong>Tracking ID:</strong>{" "}
                            {trackingDetail.trackingId}{" "}
                            <button
                              className="btn btn-outline-secondary btn-sm ms-2"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  trackingDetail.trackingId
                                )
                              }
                            >
                              Copy
                            </button>
                          </div>
                        )}
                        {trackingDetail?.trackingSite && (
                          <div className="mb-2">
                            <strong>Tracking Site:</strong>{" "}
                            <a
                              href={
                                trackingDetail.trackingSite.startsWith("http")
                                  ? trackingDetail.trackingSite
                                  : `https://${trackingDetail.trackingSite}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#007bff",
                                textDecoration: "underline",
                              }}
                            >
                              {trackingDetail.trackingSite}
                            </a>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
