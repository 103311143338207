import React from 'react'
import images from '../../images'

export default function RviewBox({reviews}) {
  return (
    <>
     {reviews.map((data, index) => {
                                return (
                                    <div className='reviewBox' key={index}>
                                        {data?.images.length > 0 ? <div className='reviewBox__images'>
                                            {data?.images.map((im, i) => {
                                                return (
                                                    <img key={i} src={im.src} alt="" />
                                                )
                                            })}
                                        </div> : null}
                                        <div className='reviewBox__content'>
                                            <div className='reviewBox__user d-flex align-items-center'>
                                                <div className='reviewBox__userImg'>
                                                    <img src={data.userImg} alt="" />
                                                </div>
                                                <div className='reviewBox__userContent'>
                                                    <div className='text-dark med font14 fontPrimary2'>{data.userName}</div>
                                                    <div className='text-dark med font14 fontPrimary2'>{data.date}</div>

                                                </div>
                                            </div>
                                            <p>{data.comment}</p>
                                            <div className='d-flex reviewBox__rating'>
                                                <div className='d-inline-flex ratingStar'>
                                                    <img src={images.star} alt="*" />
                                                    <img src={images.star} alt="*" />
                                                    <img src={images.star} alt="*" />
                                                    <img src={images.star} alt="*" />
                                                    <img src={images.star} alt="*" />
                                                </div>
                                                <div className='font14 med ms-1 primary-text'>{data.rating}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
    </>
  )
}
