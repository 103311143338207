import React from 'react'
import images from '../../images'
import RviewBox from '../../common/RviewBox/RviewBox'

const reviews = [
    {
        images: [{ src: images.review1 }, { src: images.review2 }, { src: images.review3 }, { src: images.review4 },],
        userImg: images.db,
        userName: "Dainik Bhaskar",
        date: "12 Feb, 2024",
        comment: "Bigyn komkemi Periscope alltså infrafast om content marketing. Tempostik accelerator plasm pneumafas, som kluster. Fast polyfas inbound marketing. Marknadsplatser mikropatologi. Relaterade artiklar dos, hexack i röbeck. ",
        rating: "4.8 rating",
    },
    {
        images: [],
        userImg: images.db,
        userName: "Dainik Bhaskar",
        date: "12 Feb, 2024",
        comment: "Bigyn komkemi Periscope alltså infrafast om content marketing. Tempostik accelerator plasm pneumafas, som kluster. Fast polyfas inbound marketing. Marknadsplatser mikropatologi. Relaterade artiklar dos, hexack i röbeck. ",
        rating: "4.8 rating",
    },
    {
        images: [{ src: images.review1 }, { src: images.review2 }],
        userImg: images.db,
        userName: "Dainik Bhaskar",
        date: "12 Feb, 2024",
        comment: "Bigyn komkemi Periscope alltså infrafast om content marketing. Tempostik accelerator plasm pneumafas, som kluster. Fast polyfas inbound marketing. Marknadsplatser mikropatologi. Relaterade artiklar dos, hexack i röbeck. ",
        rating: "4.8 rating",
    },
    {
        images: [{ src: images.review1 }, { src: images.review2 }, { src: images.review3 }, { src: images.review4 },],
        userImg: images.db,
        userName: "Dainik Bhaskar",
        date: "12 Feb, 2024",
        comment: "Bigyn komkemi Periscope alltså infrafast om content marketing. Tempostik accelerator plasm pneumafas, som kluster. Fast polyfas inbound marketing. Marknadsplatser mikropatologi. Relaterade artiklar dos, hexack i röbeck. ",
        rating: "4.8 rating",
    },
    {
        images: [],
        userImg: images.db,
        userName: "Dainik Bhaskar",
        date: "12 Feb, 2024",
        comment: "Bigyn komkemi Periscope alltså infrafast om content marketing. Tempostik accelerator plasm pneumafas, som kluster. Fast polyfas inbound marketing. Marknadsplatser mikropatologi. Relaterade artiklar dos, hexack i röbeck. ",
        rating: "4.8 rating",
    },
    {
        images: [{ src: images.review1 }, { src: images.review2 }],
        userImg: images.db,
        userName: "Dainik Bhaskar",
        date: "12 Feb, 2024",
        comment: "Bigyn komkemi Periscope alltså infrafast om content marketing. Tempostik accelerator plasm pneumafas, som kluster. Fast polyfas inbound marketing. Marknadsplatser mikropatologi. Relaterade artiklar dos, hexack i röbeck. ",
        rating: "4.8 rating",
    },
]

export default function Review() {
    return (
        <section className="common_sec reviewBox">
            <div className='container'>
                <div className='row'>
                    <div className='col-md-2 position-relative'>
                        <div className='heading_block mb-md-0 reviewSticky'>
                            <h3 className='mb-0 fontPrimary2 bold'>4.8</h3>
                            <p className='font14 med fontPrimary2 text-light2'>25 Reviews</p>
                            <img src={images.stars} alt="" />
                        </div>
                    </div>
                    <div className='col-md-10  position-relative'>
                        <div className='reviewSticky reviewSticky__heading'>
                            <h5 className='mb-0 fontPrimary2 '>Reviews</h5>
                        </div>
                        <RviewBox reviews={reviews} />

                    </div>
                </div>
            </div>
        </section>
    )
}
