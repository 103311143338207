import React from "react";

export default function ShippingPolicy() {
  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Shipping Policy</h3>
      <div style={styles.content}>
        <p style={styles.sectionTitle}>Shipping Methods</p>
        <p>
          <b>Standard Shipping:</b> We offer standard shipping through our
          trusted delivery partners. In some cases, our in-house delivery
          personnel will handle the delivery.
        </p>
        <p style={styles.sectionTitle}>Payment Methods</p>
        <p>
          <b>Cash on Delivery (COD):</b> You can pay for your order in cash at
          the time of delivery. This option is available for selected locations.
        </p>
        <p>
          <b>Razorpay:</b> We accept online payments through Razorpay, which
          supports various payment methods including credit/debit cards, UPI,
          and net banking.
        </p>
        <p style={styles.sectionTitle}>Shipping Charges</p>
        <p>
          Shipping charges are calculated based on the weight of the items and
          the delivery location. The exact shipping cost will be displayed at
          checkout.
        </p>
        <p style={styles.sectionTitle}>Delivery Time</p>
        <p>
          <b>Standard Shipping:</b> Orders are typically delivered within 5-7
          business days. Delivery times may vary depending on the delivery
          location and availability of the product.
        </p>
        <p style={styles.sectionTitle}>Order Processing</p>
        <p>
          Orders are processed and shipped within 1-2 business days after
          payment confirmation. Orders placed on weekends or holidays will be
          processed on the next business day.
        </p>
        <p style={styles.sectionTitle}>Order Tracking</p>
        <p>
          Once your order is shipped, you will receive a tracking number via
          email or SMS. You can use this number to track your order on the
          delivery partner's website.
        </p>
        <p style={styles.sectionTitle}>Delivery Issues</p>
        <p>
          If you encounter any issues with your delivery, please contact our
          customer service team immediately. We will work to resolve the issue
          as quickly as possible. In case of failed delivery attempts, our
          delivery partner will contact you to schedule a re-delivery.
        </p>
        <p style={styles.sectionTitle}>Address Changes</p>
        <p>
          Please ensure that your delivery address is accurate and complete. If
          you need to change your address after placing an order, contact our
          customer service team as soon as possible. Address changes after the
          order has been shipped may incur additional charges.
        </p>
        <p style={styles.sectionTitle}>Damaged or Lost Packages</p>
        <p>
          If your package is damaged or lost during transit, please contact us
          within 24 hours of receiving your order. We will initiate an
          investigation with our delivery partner and work towards a resolution,
          including reshipping the order if necessary.
        </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  content: {
    fontSize: "16px",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
  },
  list: {
    marginLeft: "20px",
    marginTop: "10px",
    listStyleType: "disc",
  },
};
