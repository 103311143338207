export default {
  logo: require("../assets/images/logo.svg").default,
  logo_plus: require("../assets/images/logo_plus.svg").default,
  logo_white: require("../assets/images/logo_white.svg").default,
  expand_more: require("../assets/images/expand_more.svg").default,
  expand_more: require("../assets/images/expand_more.svg").default,
  account_circle: require("../assets/images/account_circle.svg").default,
  local_mall: require("../assets/images/local_mall.svg").default,
  search: require("../assets/images/search.svg").default,
  support_agent: require("../assets/images/support_agent.svg").default,
  airware: require("../assets/images/airware.svg").default,
  panorama_horizontal: require("../assets/images/panorama_horizontal.svg")
    .default,
  sentiment_content: require("../assets/images/sentiment_content.svg").default,
  logo_frame: require("../assets/images/logo_frame.svg").default,
  expand_circle_right: require("../assets/images/expand_circle_right.svg")
    .default,
  stars: require("../assets/images/stars.svg").default,
  fb: require("../assets/images/fb.svg").default,
  instagram: require("../assets/images/instagram.svg").default,
  linkedin: require("../assets/images/linkedin.svg").default,
  youtube: require("../assets/images/youtube.svg").default,
  location_on: require("../assets/images/location_on.svg").default,
  call: require("../assets/images/call.svg").default,
  mail: require("../assets/images/mail.svg").default,
  shopping_bag: require("../assets/images/shopping_bag.svg").default,
  logout: require("../assets/images/logout.svg").default,
  account_circle_o: require("../assets/images/account_circle_o.svg").default,
  trending_up: require("../assets/images/trending_up.svg").default,
  color: require("../assets/images/color.svg").default,
  sim_card_download: require("../assets/images/sim_card_download.svg").default,
  wc: require("../assets/images/wc.svg").default,
  category: require("../assets/images/category.svg").default,
  close: require("../assets/images/close.svg").default,
  arrow_back: require("../assets/images/arrow_back.svg").default,
  mens: require("../assets/images/mens.jpg"),
  womens: require("../assets/images/womens.jpg"),
  banner: require("../assets/images/banner.jpg"),
  heroContent: require("../assets/images/heroContent.png"),
  heroContent_mobile: require("../assets/images/heroContent_mobile.png"),
  sustainability: require("../assets/images/sustainability.svg").default,
  quality: require("../assets/images/quality.svg").default,
  customerSatisfaction: require("../assets/images/customerSatisfaction.svg")
    .default,
  surgeon_man: require("../assets/images/surgeon-man.png"),
  surgeon_woman: require("../assets/images/surgeon-woman.png"),
  lab_coats: require("../assets/images/lab_coats.png"),
  img1: require("../assets/images/img1.jpg"),
  img2: require("../assets/images/img2.jpg"),
  img3: require("../assets/images/img3.jpg"),
  img4: require("../assets/images/img4.jpg"),
  img5: require("../assets/images/img5.jpg"),
  img6: require("../assets/images/img6.jpg"),
  img7: require("../assets/images/img7.jpg"),
  scrub: require("../assets/images/scrub.png"),
  scrub_new: require("../assets/images/scrub_new.png"),
  client_logo1: require("../assets/images/client_logo1.png"),
  client_logo2: require("../assets/images/client_logo2.png"),
  client_logo3: require("../assets/images/client_logo3.png"),
  client_logo4: require("../assets/images/client_logo4.png"),
  client_logo5: require("../assets/images/client_logo5.png"),
  client_logo6: require("../assets/images/client_logo6.png"),
  client_logo7: require("../assets/images/client_logo7.png"),
  news: require("../assets/images/news.png"),
  medilinenLogo: require("../assets/images/medilinenLogo.png"),
  google: require("../assets/images/google.png"),
  fb_btn: require("../assets/images/fb_btn.png"),
  // product detail
  slide1: require("../assets/images/slide1.png"),
  slide2: require("../assets/images/slide2.png"),
  slide3: require("../assets/images/slide3.png"),
  slide4: require("../assets/images/slide4.png"),
  signup_img: require("../assets/images/signup_img.jpg"),
  signup_img_new: require("../assets/images/signup-img.jpg"),
  banner_img_mob: require("../assets/images/banner_img_mob.png"),
  banner_bg_mob: require("../assets/images/banner_bg_mob.jpg"),

  productDetail: require("../assets/images/productDetail.svg").default,
  matetrialMaintain: require("../assets/images/matetrialMaintain.svg").default,
  returnpolicy: require("../assets/images/returnpolicy.svg").default,
  laundry: require("../assets/images/laundry.svg").default,
  iron: require("../assets/images/iron.svg").default,
  panorama_horizontalOutline:
    require("../assets/images/panorama_horizontalOutline.svg").default,
  visibility: require("../assets/images/visibility.svg").default,
  visibility_off: require("../assets/images/visibility_off.svg").default,
  star: require("../assets/images/star.svg").default,
  share: require("../assets/images/share.svg").default,

  db: require("../assets/images/db.png"),
  review1: require("../assets/images/review1.png"),
  review2: require("../assets/images/review2.png"),
  review3: require("../assets/images/review3.png"),
  review4: require("../assets/images/review4.png"),
  printLogo: require("../assets/images/printLogo.png"),

  info: require("../assets/images/info.svg").default,
  addphoto: require("../assets/images/add_photo_alternate.svg").default,
  upload: require("../assets/images/upload.svg").default,
  delete: require("../assets/images/delete.svg").default,
  add_circle: require("../assets/images/add_circle.svg").default,
  greenCheck: require("../assets/images/greenCheck.svg").default,

  download: require("../assets/images/download.svg").default,
  distance: require("../assets/images/distance.svg").default,
  redCross: require("../assets/images/redCross.svg").default,
  block: require("../assets/images/block.svg").default,
  refresh: require("../assets/images/refresh.svg").default,
};
