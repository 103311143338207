import React from "react";
import { NavLink } from "react-router-dom";
import images from "../../images";
import Rating from "react-rating";
import { formatNumberWithCommas } from "../../../App";

export default function Product(props) {
  return (
    <NavLink
      to={`/product/${props.data.slug}`}
      className={"bestseller_product"}
    >
      <div
        className="bestseller_product_img"
        // style={{
        //   minHeight: "500px",
        //   display: "flex",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
      >
        <img
          src={props.data.image}
          alt="alt"
          className="img-fluid w-100"
          style={{
            minHeight: "150px",
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "contain",
          }}
        />
        {props.data.new ? (
          <span className="new_badge">New</span>
        ) : props.data.hot ? (
          <span className="new_badge hot">Hot</span>
        ) : null}
      </div>
      <div className="bestseller_product_detail">
        <h6 className="fontPrimary2 bold mb-1 font14 text-dark">
          {props.data.name}
        </h6>
        <p className="text-light2 font14 mb-2">{props.data.category}</p>
        <h6 className="fontPrimary2 bold mb-1 font14 text-dark">
          ₹{formatNumberWithCommas(props.data.price)}
        </h6>
        <p className="text-light2 font14 mb-2">Inclusive of all taxes</p>
        <div className="">
          {props?.data?.color?.map((c, i) => {
            return (
              <span
                key={i}
                className="color_dot me-1"
                style={{ backgroundColor: c }}
              ></span>
            );
          })}
        </div>
        <hr />
        <div className="d-flex justify-content-between font14 align-items-center">
          {/* <img src={images.stars} alt="" width={90} /> */}
          <Rating
            initialRating={props.data.rating}
            fullSymbol={
              <img src={images.star} className="icon" alt="filled star" />
            }
            emptySymbol={<div style={{ width: "20px", height: "20px" }}></div>}
            readonly
          />
          {props.data.rating === undefined ? "No" : props.data.rating} rating
        </div>
      </div>
    </NavLink>
  );
}
