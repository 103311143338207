import React, { useEffect, useState } from "react";
import images from "../../images";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import { Dropdown, Modal } from "react-bootstrap";
import { isLogIn, logout } from "../../../App";
import Login from "../../Auth/Login";
import ForgotPassword from "../../Auth/ForgotPassword";
import SetPassword from "../../Auth/SetPassword";
import Signup from "../../Auth/Signup";
import CartSidebar from "./CartSidebar";
import Swal from "sweetalert2";
import {
  contactUs,
  customizationColors,
  navigationTree,
} from "../../API/fakebackend";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { API_URL } from "../../API/url";

export const colors = [
  { name: "Black", color: "#000" },
  { name: "Forest Green", color: "#294658" },
  { name: "Maroon", color: "#941826" },
  { name: "Heather Grey", color: "#62697A" },
  { name: "Steel Grey", color: "#4C494E" },
  { name: "Navy", color: "#243458" },
  { name: "Wine", color: "#3A163A" },
  { name: "Mauve", color: "#D26D7B" },
  { name: "Olive", color: "#63745E" },
  { name: "Ceil Blue", color: "#80ACE9" },
];
let submenu = (type) => [
  {
    type: "category",
    list: [
      { name: `All ${type}`, link: "/category" },
      { name: "New Arrivals", link: "/category" },
      { name: "Best Seller", link: "/category" },
      { name: "Classic", link: "/category" },
      { name: "Shop all", link: "/category" },
    ],
  },
  {
    type: "nav",
    list: [
      {
        name: "Scrubs",
        list: [
          { name: "Active 5 Pocket", link: "/category" },
          { name: "Essential 10 Pocket", link: "/category" },
          { name: "Mandarin Collar 5 Pocket", link: "/category" },
          { name: "Long Sleeve 5 Pocket", link: "/category" },
          { name: "Shop all Scrubs", link: "/category" },
        ],
      },
      {
        name: "Lab Coat Apron",
        list: [
          { name: "Chief", link: "/category" },
          { name: "Focus", link: "/category" },
          { name: "Everyday", link: "/category" },
          { name: "Shop all", link: "/category" },
        ],
      },
      {
        name: "Accessories",
        list: [
          { name: "Scrub Cap", link: "/category" },
          { name: "T-shirt", link: "/category" },
          { name: "Shop all", link: "/category" },
        ],
      },
      {
        name: "Pocket",
        list: [
          { name: "5 Pockets", link: "/category" },
          { name: "9 Pockets", link: "/category" },
          { name: "10 Pockets", link: "/category" },
          { name: "Shop all", link: "/category" },
        ],
      },
      {
        name: "Apparel",
        list: [{ name: "Underscrub", link: "/category" }],
      },
    ],
  },
  {
    type: "color",
    list: colors,
  },
];
let mostSearch = [
  "Scrubs for Mens",
  "Bedsheets",
  "Curtains",
  "Search your requirement",
  "Search your requirement",
  "Search your requirement",
];

export function Header() {
  const location = useLocation();
  const [homeMenuData, setHomeMenuData] = useState();
  const [custColors, setCustColors] = useState();
  const [email, setEmail] = useState("");
  const [phone_no, setPhone] = useState("");
  const [name, setName] = useState("");
  const [description, setRequirement] = useState("");
  const [authUser, setAuthUser] = useState("");
  const [otpData, setOtpData] = useState({ otp: "", email: "" });

  const navigationTreeData = async () => {
    try {
      const data = await navigationTree();
      setHomeMenuData(data.data);
    } catch (error) {
      console.error("Error fetching navigation tree data:", error);
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setAuthUser(JSON.parse(localStorage.getItem("authUser")));
    };

    handleStorageChange();

    window.addEventListener("storageChange", handleStorageChange);

    return () => {
      window.removeEventListener("storageChange", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (authUser) {
      navigationTreeData();
    }
  }, [authUser]);

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!email.trim()) validationErrors.email = "Email is required.";
    if (!phone_no.trim()) {
      validationErrors.phone_no = "Phone number is required.";
    } else if (!/^\d+$/.test(phone_no.trim())) {
      validationErrors.phone_no = "Phone number should only contain digits.";
    }
    if (!name.trim()) validationErrors.name = "Name is required.";
    if (!description.trim())
      validationErrors.description = "Description is required.";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const values = {
      email: email.trim(),
      phone_no: phone_no.trim(),
      name: name.trim(),
      description: description.trim(),
    };

    try {
      const response = await contactUs(values);
      if (response.result === 1) {
        toast.success("Contact Us form submitted successfully!");
        setEmail("");
        setPhone("");
        setName("");
        setRequirement("");
        setErrors({});
      } else {
        toast.error("Failed to submit the form.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    }
  };

  const [menu, setMenu] = useState([
    {
      id: 1,
      name: "Mens",
      link: "",
      submenu: submenu("Mens"),
      image: images.mens,
    },
    {
      id: 2,
      name: "Womens",
      link: "",
      submenu: submenu("Womens"),
      image: images.womens,
    },
    { id: 3, name: "Lab Coats", link: "/lab-coats", submenu: [] },
    {
      id: 4,
      name: "More",
      link: "",
      submenu: [
        {
          type: "nav",
          width: "full",
          list: [
            {
              name: "Bedsheets",
              list: [
                { name: "Disposable Bedsheets", link: "/category" },
                { name: "Reusable Bedsheets", link: "/category" },
                { name: "Waterproof Bedsheets", link: "/category" },
                { name: "Antimicrobial Bedsheets", link: "/category" },
                { name: "Shop all", link: "/category" },
              ],
            },
            {
              name: "PPE Kits",
              list: [
                { name: "PPE Kit", link: "/category" },
                { name: "Gown", link: "/category" },
                { name: "Mask", link: "/category" },
                { name: "Glasses", link: "/category" },
                { name: "Shop all", link: "/category" },
              ],
            },
            {
              name: "Gloves",
              list: [
                { name: "Latex Gloves", link: "/category" },
                { name: "Nitrile Gloves", link: "/category" },
                { name: "Vinyl Gloves", link: "/category" },
                { name: "Examination Gloves", link: "/category" },
                { name: "Shop all", link: "/category" },
              ],
            },
            {
              name: "Curtains",
              list: [
                { name: "Disposable Curtains", link: "/category" },
                { name: "Antimicrobial Curtains", link: "/category" },
                { name: "Privacy Curtains", link: "/category" },
                { name: "Cubicle Curtains", link: "/category" },
                { name: "Shop all", link: "/category" },
              ],
            },
            {
              name: "Accessories",
              list: [
                { name: "Pillowcases", link: "/category" },
                { name: "Blankets", link: "/category" },
                { name: "Towels", link: "/category" },
                { name: "Washcloths", link: "/category" },
                { name: "Shop all", link: "/category" },
              ],
            },
          ],
        },
      ],
    },
  ]);

  const searchParams = new URLSearchParams(window.location.search);
  let navigate = useNavigate();
  const setParam = (key, value) => {
    searchParams.set(key, value);
    const to = {
      pathname: window.location.pathname,
      search: searchParams.toString(),
    };
    navigate(to, { replace: true });
  };
  const deleteParam = (key) => {
    searchParams.delete(key);
    const to = {
      pathname: window.location.pathname,
      search: searchParams.toString(),
    };
    navigate(to, { replace: true });
  };
  const [showSearch, setshowSearch] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [cartOpen, setcartOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleClose = () => {
    setshowModal(false);
    setOpen(false);
    setcartOpen(false);
    deleteParam("type");
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);

    if (value.trim().length === 0) {
      setshowSearch(true);
    } else {
      setshowSearch(false);
    }
  };

  const handleClick = (type) => {
    setParam("type", type);
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  };

  const handleData = (otp, email) => {
    setOtpData({ otp, email });
  };

  const toggleNav = () => {
    setShowNav(!showNav);
    document?.body?.classList?.toggle("show_nav");
  };

  const showDD = (data) => {
    let arr = [];
    menu.map((opt) => {
      if (opt.id == data.id) {
        arr.push({ ...opt, show: !opt.show });
      } else {
        arr.push({ ...opt });
      }
    });
    setMenu(arr);
  };

  useEffect(() => {
    if (isLogIn().isToken && searchParams.get("type")) {
      deleteParam("type");
    }
    if (searchParams.get("type")) {
      setOpen(true);
    }
  }, [location.search]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchValue.trim().length > 0) {
      navigate(`/collections/${searchValue}?search`);
    }
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#082A6B",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };

  return (
    <header>
      <div className="container">
        <div className="header_inner">
          <div className="header_logo">
            <Link to="/">
              <img src={images.logo} alt="" />
            </Link>
          </div>
          <div className="header_navbar">
            <ul className="main_nav">
              {homeMenuData?.menu?.map((nav, i) => {
                return (
                  <li key={i} className={`${nav.show ? "show" : ""}`}>
                    {nav.link.length > 0 ? (
                      <NavLink to={`/collections${nav.link}`}>
                        {nav.name}
                        {nav.submenu.length ? (
                          <button
                            className="caret"
                            onClick={() =>
                              window.innerWidth < 991 ? showDD(nav) : null
                            }
                          >
                            <img
                              src={images.expand_more}
                              className="ms-1"
                              alt=""
                            />
                          </button>
                        ) : null}
                      </NavLink>
                    ) : (
                      <NavLink to="#">
                        {nav.name}
                        {nav.submenu.length ? (
                          <button
                            className="caret"
                            onClick={() =>
                              window.innerWidth < 991 ? showDD(nav) : null
                            }
                          >
                            <img
                              src={images.expand_more}
                              className="ms-1"
                              alt=""
                            />
                          </button>
                        ) : null}
                      </NavLink>
                    )}
                    {nav.submenu.length ? (
                      <div className="submenu">
                        <div className="container">
                          <div className="row g-4">
                            {nav.submenu.map((sub, ind) => {
                              return (
                                <div
                                  key={ind}
                                  className={
                                    sub.type == "nav"
                                      ? "col"
                                      : "col-lg-2 col-md-3"
                                  }
                                >
                                  <div
                                    className={
                                      sub.type == "nav" ? "row gy-3" : ""
                                    }
                                  >
                                    {sub.type == "color" ? (
                                      <div>
                                        <p className="bold primary-text mb-2 font14">
                                          Shop by Color
                                        </p>
                                        {sub?.list?.map((opt, index) => {
                                          return (
                                            <NavLink
                                              to={`/collections${opt.link}`}
                                              key={index}
                                              className="color_menu"
                                            >
                                              <span
                                                className="color_dot me-2"
                                                style={{
                                                  backgroundColor: opt.color,
                                                }}
                                              ></span>{" "}
                                              {opt.name}
                                            </NavLink>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <>
                                        {sub.list.map((opt, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className={
                                                sub.type == "nav" &&
                                                sub.width !== "full"
                                                  ? "col-xl-4 col-6"
                                                  : sub.type == "nav" &&
                                                    sub.width == "full"
                                                  ? "col"
                                                  : "col"
                                              }
                                            >
                                              <NavLink
                                                to={`${"/collections"}${
                                                  opt.link
                                                }`}
                                                className={"font14 sbold"}
                                              >
                                                {opt.name}
                                              </NavLink>
                                              {sub.type == "nav" &&
                                                opt.list.length &&
                                                opt.list.map((subNav) => {
                                                  return (
                                                    <div key={subNav.name}>
                                                      <NavLink
                                                        to={`${"/collections"}${
                                                          subNav.link
                                                        }`}
                                                        className={
                                                          "font12 med text-light"
                                                        }
                                                      >
                                                        {subNav.name}
                                                      </NavLink>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          );
                                        })}
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            <div className="col-md-3 d-lg-block d-none">
                              <img
                                src={`${API_URL}/public${nav.image}`}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="header_search">
            <img src={images.search} alt="" />
            <input
              type="text"
              className="formField search_input w-100"
              placeholder="Search your requirement"
              onChange={handleChange}
              onFocus={() => setshowSearch(true)}
              onBlur={() => setshowSearch(false)}
              onKeyPress={handleKeyPress}
            />
            {/* {showSearch ? (
              <div className="search_dropdown">
                <div className="most_search_dropdown">
                  {mostSearch.map((opt, i) => {
                    return (
                      <NavLink
                        to="/"
                        key={i}
                        className={"most_search_item med"}
                      >
                        <span>
                          <img
                            src={images.trending_up}
                            alt=""
                            className="img-fluid"
                          />
                        </span>
                        {opt}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            ) : null} */}
          </div>
          <button className="nav_toggle" onClick={toggleNav}>
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="header_accounts">
            <button onClick={() => setshowModal(true)}>
              <img src={images.support_agent} alt="" />
            </button>
            <button onClick={() => setcartOpen(true)}>
              <img src={images.local_mall} alt="" />
            </button>
            {isLogIn().isToken ? (
              <Dropdown className="header_dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={images.account_circle} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/profile">
                    <span>
                      <img src={images.account_circle_o} alt="" />
                    </span>
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item href="/my-order">
                    <span>
                      <img src={images.shopping_bag} alt="" />
                    </span>
                    My Orders
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    <span>
                      <img src={images.logout} alt="" />
                    </span>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <button onClick={() => handleClick("login")}>
                <img src={images.account_circle} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>

      {!isLogIn().isToken && searchParams.get("type") ? (
        <>
          {searchParams.get("type") == "set-password" ? (
            <SetPassword
              show={open}
              handleClick={handleClick}
              handleData={otpData}
            />
          ) : searchParams.get("type") == "signup" ? (
            <Signup
              show={open}
              handleClick={handleClick}
              handleClose={handleClose}
            />
          ) : searchParams.get("type") == "forgot" ? (
            <ForgotPassword
              show={open}
              handleClick={handleClick}
              handleData={handleData}
            />
          ) : (
            <Login
              show={open}
              handleClose={handleClose}
              handleClick={handleClick}
            />
          )}
        </>
      ) : null}

      <CartSidebar show={cartOpen} handleClose={handleClose} />

      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0 font20 fontPrimary2">Connect us</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contact_address_outer">
            <div className="row gx-5">
              <div className="col-md-6">
                <div className="contact_address">
                  <div className="contact_icon">
                    <img src={images.call} alt="" />
                  </div>
                  <div className="contact_info">
                    <p className="mb-1 primary-text font14 bold fontPrimary2">
                      Contact us at
                    </p>
                    <p className="text-dark font16 med fontPrimary2">
                      +91 9819755883 / +91 9819755881
                    </p>
                  </div>
                </div>
                <div className="contact_address">
                  <div className="contact_icon">
                    <img src={images.mail} alt="" />
                  </div>
                  <div className="contact_info">
                    <p className="mb-1 primary-text font14 bold fontPrimary2">
                      Mail us at
                    </p>
                    <p className="text-dark font16 med fontPrimary2">
                      <a href="mailto:info@medilinensolutions.com">
                        info@medilinensolutions.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="contact_address">
                  <div className="contact_icon">
                    <img src={images.location_on} alt="" />
                  </div>
                  <div className="contact_info">
                    <p className="mb-1 primary-text font14 bold fontPrimary2">
                      Registered Office
                    </p>
                    <p className="text-dark font16 med fontPrimary2">
                      SMITA CHS. DR. AMBEDKAR ROAD, MULUND WEST.
                    </p>
                  </div>
                </div>
                <div className="contact_address">
                  <div className="contact_icon">
                    <img src={images.location_on} alt="" />
                  </div>
                  <div className="contact_info">
                    <p className="mb-1 primary-text font14 bold fontPrimary2">
                      Sales Office
                    </p>
                    <p className="text-dark font16 med fontPrimary2">
                      JAI AMBE COMPLEX, 203, 2 ND FLOOR VAL VILLAGE, DADOBA
                      COMPOUND, BHIWANDI - 421302
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <input
                      type="email"
                      className="formField w-100"
                      placeholder="Enter your Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                  </div>
                  <div className="mb-2">
                    <input
                      type="text"
                      className="formField w-100"
                      placeholder="Enter your Phone no"
                      value={phone_no}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {errors.phone_no && (
                      <span className="text-danger">{errors.phone_no}</span>
                    )}
                  </div>
                  <div className="mb-2">
                    <input
                      type="text"
                      className="formField w-100"
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                  </div>
                  <div className="mb-2">
                    <textarea
                      className="formField w-100"
                      placeholder="Describe your Requirement"
                      rows={5}
                      value={description}
                      onChange={(e) => setRequirement(e.target.value)}
                    />
                    {errors.description && (
                      <span className="text-danger">{errors.description}</span>
                    )}
                  </div>
                  <div className="text-end">
                    <button type="submit" className="common_btn primary btn2">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <ToastContainer />
    </header>
  );
}
