import React from "react";
import images from "../../images";
import { NavLink } from "react-router-dom";
import "./AboutUs.css";

export default function AboutUs() {
  return (
    <main className="about-us">
      <section className="collection_section common_sec">
        <div className="container">
          <h4 className="mb-3">About Us</h4>
          <p>
            Medilinen was founded by a group of passionate individuals dedicated
            to enhancing the everyday lives of healthcare workers. With years of
            experience in the medical and textile industries, our team
            understands the demands of the healthcare environment and the
            importance of dependable, comfortable attire and linens.
          </p>
        </div>
      </section>
      <section className="banner_section">
        <div className="container">
          <div className="banner_img mb-3">
            <img
              src={images.heroContent}
              alt=""
              className="img-fluid w-100 d-none d-md-block"
            />
            <div className="banner_mobile">
              <img
                src={images.banner_img_mob}
                alt=""
                className="img-fluid w-100"
              />
            </div>
          </div>
          <div className="banner_content_1">
            <h6>Who we are</h6> <br />
            <h5>Our Mission</h5>
            <div>
              <p style={{ color: "gray", fontSize: "18px" }}>
                At Medilinen, our mission is to provide healthcare professionals
                with high-quality medical apparel and linens that offer both
                comfort and durability. We strive to meet the unique needs of
                the medical community by delivering products that combine
                functionality, style, and reliability.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="collection_section common_sec">
        <div className="container">
          <div className="heading_blocks">
            <div className="heading_block">
              <img
                loading="lazy"
                alt=""
                src={images.quality}
                className="block_image"
              />
              <h2>Quality & Commitment</h2>
              <p>
                At Medilinen, quality is paramount. We meticulously select
                materials and enforce strict quality controls to ensure our
                products meet the highest standards. You can trust Medilinen for
                reliable, long-lasting products.
              </p>
            </div>
            <div className="heading_block">
              <img
                loading="lazy"
                alt=""
                src={images.customerSatisfaction}
                className="block_image"
              />
              <h2>Customer Satisfaction</h2>
              <p>
                Your satisfaction is our priority. We offer exceptional service
                and support, whether you’re outfitting a medical facility or
                purchasing individual items. Our team is here to help every step
                of the way.
              </p>
            </div>
            <div className="heading_block">
              <img
                loading="lazy"
                alt=""
                src={images.sustainability}
                className="block_image"
              />
              <h2>Sustainability</h2>
              <p>
                We are dedicated to eco-friendly practices, minimizing
                environmental impact through responsible sourcing and
                production. Our goal is to provide high-quality, environmentally
                conscious products.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
