import React, { useState } from "react";
import images from "../images";
import { forgotPassword } from "../API/fakebackend";

export default function SetPassword(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordError) {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (passwordError) {
      setPasswordError("");
    }
  };

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    try {
      const response = await forgotPassword({
        email: props.handleData.email,
        otp: props.handleData.otp,
        password: password,
      });
      props.handleClick("login");
    } catch (error) {
      console.error("Error setting password:", error);
    }
  };

  return (
    <div className={`login_modal ${props.show ? "show" : ""}`}>
      <div className="login_modal_container">
        <div className="login_modal_header">
          <img src={images.logo} alt="" width={200} />
        </div>
        <div className="login_modal_form">
          <h4 className="font20 text-dark bold fontPrimary2 mb-4">
            Setup New password
          </h4>
          <div>
            <div className="mb-2">
              <input
                type="password"
                className="formField w-100"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="mb-2">
              <input
                type="password"
                className="formField w-100"
                placeholder="Confirm New password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {passwordError && <p className="text-danger">{passwordError}</p>}
            </div>
            <div className="login_modal_header mt-4 align-items-center">
              <button
                className="common_btn primary btn2"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
